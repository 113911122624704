import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

import "core-js/modules/es.array.join.js";
var render = function render() {
  var _vm$data, _vm$data2, _vm$data3, _vm$data5, _vm$data6, _vm$data7, _vm$data8, _vm$data9, _vm$data11, _vm$data13, _vm$data14, _vm$data15;
  var _vm = this,
    _c = _vm._self._c;
  return _c(VContainer, {
    staticClass: "fill-height justify-center",
    attrs: {
      "fluid": ""
    }
  }, [_c('ContentArea', {
    staticClass: "content-area d-flex flex-column align-center justify-center"
  }, [_c('div', {
    staticClass: "rounded-circle primary d-flex",
    staticStyle: {
      "width": "48px",
      "height": "48px"
    }
  }, [_c('div', {
    staticStyle: {
      "margin": "10px"
    }
  }, [_c(VIcon, {
    attrs: {
      "size": "28",
      "color": "color-surface"
    }
  }, [_vm._v("mdi-check")])], 1)]), _vm._v(" "), _c('h5', {
    staticClass: "mt-4"
  }, [_vm._v("\n      " + _vm._s(_vm.$t('success.createOrder')) + "\n    ")]), _vm._v(" "), !_vm.canCreateOrder ? _c('div', {
    staticClass: "text-body-1 mt-4"
  }, [_vm._v("\n      " + _vm._s((_vm$data = _vm.data) !== null && _vm$data !== void 0 && _vm$data.isDigitalGift ? _vm.$t('extension.waitingApproveWowLinkWithContact') : _vm.$t('extension.waitingApproveDeliveryWithContact')) + "\n    ")]) : _vm._e(), _vm._v(" "), !((_vm$data2 = _vm.data) !== null && _vm$data2 !== void 0 && _vm$data2.isDigitalGift) && !_vm.contactId ? [(_vm$data3 = _vm.data) !== null && _vm$data3 !== void 0 && _vm$data3.orderId ? _c('TextButton', {
    staticClass: "mt-4",
    attrs: {
      "value": _vm.$t('action.doCheckOrderDetail')
    },
    on: {
      "click": function click($event) {
        var _vm$data4;
        return _vm.$open("/orders/".concat((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.orderId));
      }
    }
  }) : _vm._e()] : [_c(VCard, {
    staticClass: "mt-6 pa-6",
    staticStyle: {
      "max-width": "400px"
    },
    attrs: {
      "elevation": "0",
      "outlined": ""
    }
  }, [(_vm$data5 = _vm.data) !== null && _vm$data5 !== void 0 && _vm$data5.isDigitalGift ? [_vm.contactId ? _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": 1
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('extension.wowLinkSection')) + "\n          ")]) : _vm._e(), _vm._v(" "), _vm.enableWowlinkTypeFieldTexts.length ? [_c('div', {
    staticClass: "d-flex mb-2"
  }, [_c('IconsSalesforce'), _vm._v(" "), _c('div', {
    staticClass: "text-subtitle-1 ml-1"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('extension.wowLinkSyncCase')) + "\n              ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "text-body-1 mb-1"
  }, [_vm._v("\n              " + _vm._s((_vm$data6 = _vm.data) !== null && _vm$data6 !== void 0 && _vm$data6.lp_url ? _vm.$t('extension.wowLinkSyncDesc', [_vm.enableWowlinkTypeFieldTexts.join(_vm.$t('comma'))]) : _vm.$t('extension.wowLinkSyncDescNeedApprove', [_vm.enableWowlinkTypeFieldTexts.join(_vm.$t('comma'))])) + "\n            ")]), _vm._v(" "), _c('div', {
    staticClass: "text-caption secondary-text--text mb-4"
  }, [_vm._v("\n              " + _vm._s(_vm.$t('extension.wowLinkSyncHelp1')) + "\n              "), _c('LinkText', {
    attrs: {
      "to": "https://sendwow.notion.site/WOW-fd408f2a2caf4a328af89e8bad6b411b",
      "text": _vm.$t('extension.wowLinkSyncHelp2')
    }
  }), _vm._v("\n              " + _vm._s(_vm.$t('extension.wowLinkSyncHelp3')) + "\n            ")], 1), _vm._v(" "), _c('div', {
    staticClass: "d-flex mb-2"
  }, [_c(VIcon, {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-content-copy")]), _vm._v(" "), _c('div', {
    staticClass: "text-subtitle-1 ml-1"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('extension.wowLinkCopyCase')) + "\n              ")])], 1)] : _vm._e(), _vm._v(" "), (_vm$data7 = _vm.data) !== null && _vm$data7 !== void 0 && _vm$data7.lp_url ? [_c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n              " + _vm._s(_vm.$t('extension.wowLinkCopyDesc')) + "\n            ")]), _vm._v(" "), _c('Input', {
    staticClass: "mt-2",
    staticStyle: {
      "flex": "none",
      "width": "100%"
    },
    attrs: {
      "type": "text",
      "readonly": "",
      "value": (_vm$data8 = _vm.data) === null || _vm$data8 === void 0 ? void 0 : _vm$data8.lp_url
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-center"
        }, [_c(VDivider, {
          staticClass: "mx-2",
          attrs: {
            "vertical": ""
          }
        }), _vm._v(" "), _c('TextButton', {
          staticClass: "mr-1",
          attrs: {
            "value": _vm.$t('action.copy'),
            "icon": "mdi-content-copy"
          },
          on: {
            "click": _vm.onClickCopy
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 847127402)
  }), _vm._v(" "), _c('div', {
    staticClass: "text-caption secondary-text--text mt-2"
  }, [_vm._v("\n              " + _vm._s(_vm.$t('extension.wowLinkPrecaution')) + "\n            ")]), _vm._v(" "), (_vm$data9 = _vm.data) !== null && _vm$data9 !== void 0 && _vm$data9.orderId ? [_c('div', {
    staticClass: "text-body-1 mt-2"
  }, [_vm._v("\n                " + _vm._s(_vm.$t('extension.orCheckFromOrder')) + "\n              ")]), _vm._v(" "), _c('TextButton', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.$t('action.doCheckOrderDetail')
    },
    on: {
      "click": function click($event) {
        var _vm$data10;
        return _vm.$open("/orders/".concat((_vm$data10 = _vm.data) === null || _vm$data10 === void 0 ? void 0 : _vm$data10.orderId));
      }
    }
  })] : _vm._e()] : [_c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n              " + _vm._s(_vm.$t('extension.wowLinkCopyDescNeedApprove')) + "\n            ")]), _vm._v(" "), (_vm$data11 = _vm.data) !== null && _vm$data11 !== void 0 && _vm$data11.orderId ? _c('TextButton', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.$t('action.doCheckOrderDetail')
    },
    on: {
      "click": function click($event) {
        var _vm$data12;
        return _vm.$open("/orders/".concat((_vm$data12 = _vm.data) === null || _vm$data12 === void 0 ? void 0 : _vm$data12.orderId));
      }
    }
  }) : _vm._e()]] : _vm._e(), _vm._v(" "), (_vm$data13 = _vm.data) !== null && _vm$data13 !== void 0 && _vm$data13.isDigitalGift && _vm.contactId ? _c(VDivider, {
    staticClass: "my-6"
  }) : _vm._e(), _vm._v(" "), _vm.contactId ? [(_vm$data14 = _vm.data) !== null && _vm$data14 !== void 0 && _vm$data14.isDigitalGift ? _c('SectionSubTitle', {
    staticClass: "mb-4",
    attrs: {
      "number": 2
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('extension.tagSection')) + "\n          ")]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "text-body-1"
  }, [_vm._v("\n            " + _vm._s((_vm$data15 = _vm.data) !== null && _vm$data15 !== void 0 && _vm$data15.isDigitalGift ? _vm.$t('extension.tagDescriptionWithWowLink') : _vm.$t('extension.tagDescriptionWithDelivery')) + "\n          ")]), _vm._v(" "), _c('SmallLabel', {
    staticClass: "mt-4",
    attrs: {
      "label": _vm.$t('tag')
    }
  }), _vm._v(" "), _c('TagSelect', {
    attrs: {
      "value": _vm.form.tags,
      "items": _vm.tags
    },
    on: {
      "update:value": function updateValue($event) {
        _vm.form.tags = $event;
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "d-flex flex-column align-center"
  }, [_c('button', {
    staticClass: "tw-btn tw-btn-fill-primary mt-4",
    attrs: {
      "type": "button",
      "disabled": !_vm.isSubmitEnable
    },
    on: {
      "click": _vm.onClickSave
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t('action.doAdd')) + "\n            ")])])] : _vm._e()], 2)], _vm._v(" "), _c('button', {
    staticClass: "tw-btn tw-btn-outline-primary mt-6",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("\n      " + _vm._s(_vm.$t('action.close')) + "\n    ")])], 2)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };